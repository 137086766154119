






















































































































































































































































































































import { SearchBuilder } from "@/builder";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import { toTitlecase } from "@/helpers/common";
import { debounce } from "@/helpers/debounce";
import { useAsset, useProduct } from "@/hooks";
import { SparePartRequestMapper } from "@/mapper/SparepartRequest.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ListContactDataDto } from "@/models/interface/contact-data";
import { AssetResponseDto } from "@/models/interface/master-asset";
import { ProductStockResponseDto } from "@/models/interface/master-product";
import {
  CreateSparePartRequestDto,
  SparePartRequestFormPartState,
  SparePartRequestFormState,
  SparePartRequestFormUnitState,
  SparePartRequestRefState,
} from "@/models/interface/sparepart-request";
import { sparePartRequestService } from "@/services/sparepart-request.service";
import { ColumnDef } from "@/types";
import { StringUtils } from "@/utils";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Prop, Ref } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  components: {
    SelectCustomer,
    SelectMechanic,
    SelectBranch,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
})
export default class CreateSparepartRequest extends MNotification {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;
  toTitlecase = toTitlecase;

  @Prop({ required: false, default: undefined })
  id!: string | undefined;

  @Ref("formRef")
  formRef!: FormModel;

  dtReference: SparePartRequestRefState = new SparePartRequestRefState();
  formData: SparePartRequestFormState = new SparePartRequestFormState();
  currentUnitPage = 1;

  formRules = {
    requestDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    customer: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    mechanic: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    branch: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  columns = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      key: "equipment",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      key: "brand",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      key: "model",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_spec"),
      dataIndex: "spec",
      key: "spec",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      customRender: (text: string) => text || "-",
    },
  ];

  innerColumns = [
    {
      title: this.$t("lbl_image"),
      key: "image",
      dataIndex: "partRef",
      scopedSlots: { customRender: "image" },
    },
    {
      title: this.$t("lbl_part_reference"),
      dataIndex: "partRef",
      key: "partRef",
      scopedSlots: { customRender: "partRef" },
    },
    {
      title: this.$t("lbl_quantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      scopedSlots: { customRender: "quantity" },
    },
    {
      title: this.$t("common.reference-text", { text: this.$t("lbl_uom") }),
      dataIndex: "uomRef",
      key: "uomRef",
      scopedSlots: { customRender: "uomRef" },
      width: 120,
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "note",
      key: "note",
      scopedSlots: { customRender: "note" },
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      width: 80,
      scopedSlots: { customRender: "action" },
    },
  ] as ColumnDef[];

  loading = {
    fetchUnitCode: false,
    fetchPartNumber: false,
    createSparepartRequest: false,
    detail: false,
    reject: false,
  };

  storeBaseDecimalPlace!: number;
  selectedRowIds: string[] = [];
  unitCodeOptionsCache: Option<AssetResponseDto>[] = [];
  unitCodeOptions: Option<AssetResponseDto>[] = [];
  partNumberOptions: Option<ProductStockResponseDto>[] = [];
  partNameOptions: Option<ProductStockResponseDto>[] = [];

  imageViewData = {
    url: "" as string | null,
    visible: false,
  };

  created(): void {
    this.getUnitCodeList();
    this.getPartList();

    if (this.id) {
      this.getDetail(this.id);
    }
  }

  get isUpdate(): boolean {
    return !!this.formData.documentNo;
  }

  get allRowIds(): string[] {
    return this.formData.units.map(line => line.key);
  }

  get hasReference(): boolean {
    return !!this.dtReference.customer;
  }

  get isEdit() {
    return !!this.id;
  }

  get title() {
    if (this.isEdit) {
      return this.$t("common.edit-text", {
        text: this.$t("lbl_sparepart_request_form"),
      });
    } else {
      return this.$t("common.create-text", {
        text: this.$t("lbl_sparepart_request_form"),
      });
    }
  }

  getPartList() {
    this.loading.fetchPartNumber = true;
    this.fetchProductInStock({})
      .then(response => {
        this.partNumberOptions = response.map(item => ({
          label: item.code,
          value: item.id,
          key: item.id,
          meta: item,
        }));
        this.partNameOptions = response.map(item => ({
          label: item.name,
          value: item.id,
          key: item.id,
          meta: item,
        }));
      })
      .finally(() => (this.loading.fetchPartNumber = false));
  }

  getUnitCodeList() {
    this.loading.fetchUnitCode = true;
    this.fetchUnitCode()
      .then(options => {
        this.unitCodeOptions = options;
        this.unitCodeOptionsCache = options;
      })
      .finally(() => (this.loading.fetchUnitCode = false));
  }

  //#region utilities
  resetAutofilledLine(ref: SparePartRequestFormUnitState): void {
    this.$set(ref, "equipment", "");
    this.$set(ref, "brand", "");
    this.$set(ref, "model", "");
    this.$set(ref, "serialNumber", "");
    this.$set(ref, "spec", "");
    this.$set(ref, "type", "");
    this.$set(ref, "location", "");
  }

  findUnitCodeOption(
    option: Option<AssetResponseDto>,
    record: SparePartRequestFormUnitState
  ): Option<AssetResponseDto> | undefined {
    let selectedOption = this.unitCodeOptions.find(o => o.key == option.key);

    if (selectedOption) {
      return selectedOption;
    }
    return record.unitCodeOptions.find(o => o.key == option.key);
  }
  //#endregion utilities

  //#region api requests
  async fetchUnitCode(search = ""): Promise<Option<AssetResponseDto>[]> {
    const { findAllAsset, toOptionsNew } = useAsset();

    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const criteria = builder
      .push(["status", "Retired"])
      .or()
      .push(["status", "Rented"])
      .or()
      .push(["status", "Ready"])
      .or()
      .push(["status", "Need To Repair"])
      .build();
    const queries: string[] = [criteria];
    if (search) {
      queries.push(
        builder.push(["unitCode", search], { like: "both" }).build()
      );
    }
    if (this.formData.customer) {
      queries.push(
        builder.push(["customer.secureId", this.formData.customer.key]).build()
      );
    }
    params.search = queries.join(builder.AND);

    const assets = await findAllAsset(params);

    return toOptionsNew(assets.data);
  }

  async fetchProductInStock({
    code,
    name,
  }: Partial<{
    code: string;
    name: string;
  }>): Promise<ProductStockResponseDto[]> {
    const { searchProductInStock, findAllProductInStock } = useProduct();

    const params = new RequestQueryParams();
    params.search = searchProductInStock({
      code: code,
      name: name,
    });

    const response = await findAllProductInStock(params);
    return response.data;
  }

  getDetail(srfId: string): void {
    this.loading.detail = true;
    sparePartRequestService
      .getDetail(srfId)
      .then(response => {
        this.formData =
          SparePartRequestMapper.toSparePartRequestFormState(response);
        this.formData.units.forEach(unit => {
          unit.parts.forEach(part => {
            part.disabled = true;
          });
        });

        if (StringUtils.compare(response.status, "NEED_APPROVAL")) {
          this.dtReference =
            SparePartRequestMapper.toSparePartRequestFormRefState(response);
        }
      })
      .finally(() => {
        this.loading.detail = false;
      });
  }

  handleApprove(): void {
    this.formRef.validate(valid => {
      if (!valid || !this.validateUnits(this.formData.units)) {
        this.showNotifError("notif_validation_error");
        return;
      }
      const dto = SparePartRequestMapper.toCreateDto(this.formData);
      this.createSparePartRequest(dto);
    });
  }

  reject(): void {
    if (!this.id) return;
    this.loading.reject = true;
    sparePartRequestService
      .reject(this.id)
      .then(() => {
        this.showNotifSuccess("notif_reject_success", {
          documentNumber: this.formData.documentNo,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.reject = false;
      });
  }

  confirmReject(): void {
    this.showConfirmationReject(this.formData.documentNo, () => {
      this.reject();
    });
  }
  //#endregion api requests

  //#region event handlers
  /**
   * Jika customer dihapus, set default options dengan cache dan set setiap line ke default options
   * Jika customer diganti, fetch ulang unit code options pada line dan set default optionsnya
   * Jika customer dihapus atau diganti, reset semua unit code agar selalu sinkron
   */
  handleCustomerChange(option: Option<ListContactDataDto> | undefined): void {
    const customer = option?.meta;
    if (!customer) {
      this.unitCodeOptions = [...this.unitCodeOptionsCache];
      this.formData.units.forEach(line => (line.useLineOptions = false));
      return;
    }

    this.formData.units.forEach(line => {
      line.unitCodeLoading = true;
      line.unitCode = undefined;
    });
    this.fetchUnitCode().then(options => {
      this.unitCodeOptions = options;
      this.formData.units.forEach(line => {
        line.useLineOptions = true;
        line.unitCodeOptions = options;
        line.unitCodeLoading = false;
      });
    });
  }

  handleSelectedRowIdChange(values: string[]): void {
    this.selectedRowIds = values;
  }

  handleAddRow(): void {
    this.formData.units.push(new SparePartRequestFormUnitState());
    this.currentUnitPage = this.formData.units.length;
  }

  handleAssetSelected(
    option: Option<AssetResponseDto> | undefined,
    record: SparePartRequestFormUnitState
  ): void {
    if (!option) {
      this.resetAutofilledLine(record);
      record.useLineOptions = false;
      return;
    }

    const selectOption = this.findUnitCodeOption(option, record);
    const asset = selectOption?.meta;
    if (!asset) {
      this.resetAutofilledLine(record);
      return;
    }

    const assetCategoryIdSegments = asset.assetCategory.id.split(".");
    this.$set(
      record,
      "equipment",
      assetCategoryIdSegments[0]?.toUpperCase() ?? ""
    );
    this.$set(record, "brand", assetCategoryIdSegments[1]?.toUpperCase() ?? "");
    this.$set(record, "model", asset.model);
    this.$set(record, "serialNumber", asset.serialNumber);
    this.$set(record, "spec", asset.description);
    this.$set(record, "type", asset.type);
    this.$set(record, "location", asset.customerLocation);
  }

  handleAssetSearch(
    search: string,
    record: SparePartRequestFormUnitState
  ): void {
    debounce(() => {
      record.unitCodeLoading = true;
      this.fetchUnitCode(search)
        .then(res => (record.unitCodeOptions = res))
        .finally(() => {
          record.unitCodeLoading = false;
          record.useLineOptions = true;
        });
    });
  }

  handleAddPart(line: SparePartRequestFormUnitState): void {
    line.parts.push(new SparePartRequestFormPartState());
  }

  handleDeleteRow(): void {
    const lines: SparePartRequestFormUnitState[] = [];
    const deletedUnitIds: string[] = [];
    this.formData.units.forEach(unit => {
      if (this.selectedRowIds.includes(unit.key)) {
        deletedUnitIds.push(unit.sparePartUnitId);
      } else {
        lines.push(unit);
      }
    });

    this.formData.units = [...lines];
    this.formData.deletedUnitIds.push(...deletedUnitIds);
    this.selectedRowIds = [];
    this.currentUnitPage -= 1;
  }

  handleDeletePart(
    record: SparePartRequestFormUnitState,
    part: SparePartRequestFormPartState
  ): void {
    const NOT_FOUND = -1;
    const deleteIndex = record.parts.findIndex(p => p.key === part.key);
    if (deleteIndex === NOT_FOUND) return;

    this.formData.deletedPartIds.push(
      record.parts[deleteIndex].sparePartItemId
    );
    record.parts.splice(deleteIndex, 1);
  }

  handleBack(): void {
    this.$router.push({ name: "logistic.sparepart-request" });
  }

  validateUnits(units: SparePartRequestFormUnitState[]): boolean {
    if (units.length === 0) {
      this.showNotifError("notif_at_least_one_unit");
      return false;
    }

    for (const line of units) {
      if (!line.unitCode) {
        this.showNotifError("notif_validation_error");
        return false;
      }

      for (const part of line.parts) {
        if (!part.partRef || !part.uomRef) {
          this.showNotifError("notif_validation_error");
          return false;
        }
      }
    }

    return true;
  }

  createSparePartRequest(dto: CreateSparePartRequestDto): void {
    this.loading.createSparepartRequest = true;
    sparePartRequestService
      .createSparepartRequest(dto)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.handleBack();
      })
      .finally(() => (this.loading.createSparepartRequest = false));
  }

  handleSubmit(): void {
    this.formRef.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifError("notif_validation_error");
        return;
      }

      if (!this.validateUnits(this.formData.units)) {
        return;
      }

      const dto = SparePartRequestMapper.toCreateDto(this.formData);
      this.createSparePartRequest(dto);
    });
  }

  showImage(part: SparePartRequestFormPartState): void {
    if (part.imageUrl) {
      this.imageViewData.url = part.imageUrl;
      this.imageViewData.visible = true;
    }
  }

  closeImage(): void {
    this.imageViewData.visible = false;
    this.imageViewData.url = null;
  }
  //#endregion event handlers
}
